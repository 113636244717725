
import Vue, { PropType } from 'vue'
import Component from 'vue-class-component'
import { namespace } from 'vuex-class'
import { getModule } from 'vuex-module-decorators';

import DefinitionActions from '@/components/DefinitionActions.vue'
import { Tag, formatTimestamp } from '@/lib'
import { JapaneseDefinition } from '@/lib/japanese'
import JapaneseModule from '@/store/modules/japanese'
const japanese = namespace('japanese')

const Props = Vue.extend({
  props: {
    item: Object as PropType<JapaneseDefinition>,
  }
})

@Component({
  name: 'DefinitionListItem',
  components: {
    DefinitionActions,
  },
})
export default class DefinitionListItem extends Props {
  /* eslint-disable no-unused-vars */
  @japanese.Mutation
  addTagFilter!: (id: string) => void
  /* eslint-enable no-unused-vars */

  get tags(): Record<string, Tag> {
    const module = getModule(JapaneseModule, this.$store);
    return module.tags
  }

  parentTags(tagID: string): string {
    const parentTags: Tag[] = []
    let parentTagsString = ''

    let tag = this.tags[tagID]
    if (!tag) {
      return ''
    }

    while (tag.parentID) {
      tag = this.tags[tag.parentID]
      parentTags.push(tag)
    }

    parentTags.reverse()
    for (const tag of parentTags) {
      parentTagsString += tag.name + ': '
    }

    return parentTagsString
  }

  formatTimestamp(t: number): string {
    return formatTimestamp(t)
  }
}
