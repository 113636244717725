import Vue from 'vue'
import { VuexModule, Module, Mutation, Action, config } from 'vuex-module-decorators'
import { Index, Hit } from 'folder-ts'
import { v4 as uuidv4 } from 'uuid'

import { localDB } from '@/lib/common'
import { Tag, EMPTY_TAG, TranslationDefinitionTestResult, FullTag } from '@/lib'
import { JapaneseDefinition, JMDictDocument } from '@/lib/japanese'
import { ASSETS_BASE_URL } from '@/common'

// Set rawError to true by default on all @Action decorators
config.rawError = true

const index = new Index('index', [ASSETS_BASE_URL, 'jmdict'].join('/'))

function preProcessQuery(query: string): string {
  const tokens: string[] = query.split(' ')
  const finalTokens: string[] = []

  for (const token of tokens) {
    finalTokens.push(token)
    const hiraganaToken = convertToHiragana(token.toLowerCase())
    if (hiraganaToken) {
      finalTokens.pop()
      finalTokens.push(hiraganaToken)
    }
  }

  return finalTokens.join(' ')
}

const syllableHiraganaMapOne: any = {
  'a': 'あ',
  'i': 'い',
  'u': 'う',
  'e': 'え',
  'o': 'お',
  'n': 'ん',
}

const syllableHiraganaMapTwo: any = {
  'ha': 'は',
  'hi': 'ひ',
  'fu': 'ふ',
  'hu': 'ふ',
  'he': 'へ',
  'ho': 'ほ',
  'ba': 'ば',
  'bi': 'び',
  'bu': 'ぶ',
  'be': 'べ',
  'bo': 'ぼ',
  'ra': 'ら',
  'ri': 'り',
  'ru': 'る',
  're': 'れ',
  'ro': 'ろ',
  'ta': 'た',
  'tu': 'つ',
  'tsu': 'つ',
  'te': 'て',
  'to': 'と',
  'da': 'だ',
  'di': 'ぢ',
  'du': 'づ',
  'de': 'で',
  'do': 'ど',
  'sa': 'さ',
  'si': 'し',
  'su': 'す',
  'se': 'せ',
  'so': 'そ',
  'za': 'ざ',
  'zi': 'じ',
  'zu': 'ず',
  'ze': 'ぜ',
  'zo': 'ぞ',
  'ka': 'か',
  'ki': 'き',
  'ku': 'く',
  'ke': 'け',
  'ko': 'こ',
  'ga': 'が',
  'gi': 'ぎ',
  'gu': 'ぐ',
  'ge': 'げ',
  'go': 'ご',
  'ja': 'じゃ',
  'ji': 'じ',
  'ju': 'じゅ',
  'je': 'じぇ',
  'jo': 'じょ',
  'ma': 'ま',
  'mi': 'み',
  'mu': 'む',
  'me': 'め',
  'mo': 'も',
  'na': 'な',
  'ni': 'に',
  'nu': 'ぬ',
  'ne': 'ね',
  'no': 'の',
  'pa': 'ぱ',
  'pi': 'ぴ',
  'pu': 'ぷ',
  'pe': 'ぺ',
  'po': 'ぽ',
}

const syllableHiraganaMapTwoW: any = {
  'wa': 'わ',
  'wi': 'ゐ',
  'we': 'ゑ',
  'wo': 'を',
}

const syllableHiraganaMapTwoY: any = {
  'ya': 'や',
  'yu': 'ゆ',
  'yo': 'よ',
}

const syllableHiraganaMapThree: any = {
  'bya': 'びゃ',
  'byu': 'びゅ',
  'byo': 'びょ',
  'gya': 'ぎゃ',
  'gyu': 'ぎゅ',
  'gyo': 'ぎょ',
  'hya': 'ひゃ',
  'hyu': 'ひゅ',
  'hyo': 'ひょ',
  'jya': 'じゃ',
  'jyu': 'じゅ',
  'jyo': 'じょ',
  'kya': 'きゃ',
  'kyu': 'きゅ',
  'kyo': 'きょ',
  'mya': 'みゃ',
  'myu': 'みゅ',
  'myo': 'みょ',
  'nya': 'にゃ',
  'nyu': 'にゅ',
  'nyo': 'にょ',
  'pya': 'ぴゃ',
  'pyu': 'ぴゅ',
  'pyo': 'ぴょ',
  'rya': 'りゃ',
  'ryu': 'りゅ',
  'ryo': 'りょ',
}

const syllableHiraganaMapThreeCS: any = {
  'cha': 'ちゃ',
  'chi': 'ち',
  'chu': 'ちゅ',
  'che': 'ちぇ',
  'cho': 'ちょ',
  'shi': 'し',
  'shu': 'しゅ',
  'she': 'しぇ',
  'sho': 'しょ',
}

function convertToHiragana(token: string): string {
  const vowels = ['a','i','u','e','o']
  const vowelsTwoW = ['a', 'i', 'e', 'o']
  const vowelsTwoY = ['a', 'u', 'o']
  const vowelsThree = ['a', 'u', 'o']
  const consonantsTwo = ['f', 'h','b','r','t','d','s','z','k','g','j','m','n','p']
  const consonantsThree = ['b','g','h','j','k','m','n','p','r']
  const consonantsThreeCS = ['c', 's']
  const consonantsEmphasis = ['c', 'k', 'p', 's', 't']

  let hiragana = ''
  let syllable = ''
  let i = -1

  for (const c of token) {
    syllable += c
    i += 1

    if (vowels.indexOf(syllable[0]) >= 0) {
      hiragana += syllableHiraganaMapOne[syllable[0]]
      syllable = syllable.substring(1)
    } else if (syllable.length >= 3) {
      if (consonantsThree.indexOf(syllable[0]) >= 0 && syllable[1] === 'y' && vowelsThree.indexOf(syllable[2]) >= 0) {
        hiragana += syllableHiraganaMapThree[syllable.substring(0, 3)]
        syllable = syllable.substring(3)
      } else if (consonantsThreeCS.indexOf(syllable[0]) >= 0 && syllable[1] === 'h' && vowels.indexOf(syllable[2]) >= 0) {
        hiragana += syllableHiraganaMapThreeCS[syllable.substring(0, 3)]
        syllable = syllable.substring(3)
      } else if (syllable === 'tsu') {
        hiragana += 'つ'
        syllable = syllable.substring(3)
      }
    } else if (syllable.length >= 2) {
      if (syllable[0] === 'n') {
        if (vowels.indexOf(syllable[1]) >= 0) {
          hiragana += syllableHiraganaMapTwo[syllable]
          syllable = syllable.substring(2)
        } else {
          hiragana += syllableHiraganaMapOne[syllable[0]]
          syllable = syllable.substring(1)
        }
      } else if (consonantsEmphasis.indexOf(syllable[0]) && syllable[0] === syllable[1]) {
        hiragana += 'っ'
        syllable = syllable.substring(1)
      } else if (consonantsTwo.indexOf(syllable[0]) >= 0 && vowels.indexOf(syllable[1]) >= 0) {
        hiragana += syllableHiraganaMapTwo[syllable.substring(0, 2)]
        syllable = syllable.substring(2)
      } else if (syllable[0] === 'w' && vowelsTwoW.indexOf(syllable[1]) >= 0) {
        hiragana += syllableHiraganaMapTwoW[syllable.substring(0, 2)]
        syllable = syllable.substring(2)
      } else if (syllable[0] === 'y' && vowelsTwoY.indexOf(syllable[1]) >= 0) {
        hiragana += syllableHiraganaMapTwoY[syllable.substring(0, 2)]
        syllable = syllable.substring(2)
      }
    } else if (i+1 === token.length) {
      if (syllable[0] === 'n') {
        hiragana += 'ん'
        syllable = syllable.substring(1)
      }
    }
  }

  if (syllable.length > 0) {
    return token
  }

  return hiragana
}

@Module({ name: 'japanese', namespaced: true })
class Japanese extends VuexModule {
  ready: boolean = false
  searching: boolean = false
  loadedShards: number = 0
  savedDefinitions: {[key: string]: JapaneseDefinition} = {}
  currentItem: JapaneseDefinition | null = null
  currentPage: number = 1
  hits: Hit[] = []
  syncing = false

  // Tags
  tags: Record<string, Tag> = {}
  selectedTags: string[] = []
  tagCreating = ''
  tagEditing = EMPTY_TAG
  editedTag = EMPTY_TAG
  isEditingTag = false
  tagFilters: string[] = []

  infoSnackbar = false
  infoSnackbarText = ''
  errorSnackbar = false
  errorSnackbarText = ''

  // Definition details dialog
  definitionDetailsDialog = false
  definitionDetailsDocument: JMDictDocument | null = null

  // Filter dialog
  bShowFilterDialog = false

  @Mutation
  setReady(ready: boolean): void {
    this.ready = ready
  }

  @Mutation
  setSearching(searching: boolean): void {
    this.searching = searching
  }

  @Mutation
  setHits(hits: Hit[]): void {
    this.hits = hits
  }

  @Mutation
  showInfo(msg: string): void {
    this.infoSnackbar = true
    this.infoSnackbarText = msg
  }

  @Mutation
  showError(msg: string): void {
    this.errorSnackbar = true
    this.errorSnackbarText = msg
  }

  @Mutation
  setInfoSnackbar(value: boolean): void {
    this.infoSnackbar = value
  }

  @Mutation
  setErrorSnackbar(value: boolean): void {
    this.errorSnackbar = value
  }

  @Mutation
  reloadSavedDefinitions(docs: any[]): void {
    for (const key in this.savedDefinitions) {
      Vue.delete(this.savedDefinitions, key)
    }

    for (const doc of docs) {
      Vue.set(this.savedDefinitions, doc._id, doc)
    }
  }

  // Tags

  @Action
  async createTag(parentID: string | null) {
    let name: string | null = ''

    if (parentID) {
      name = prompt('What is the tag name?')
    } else {
      name = this.tagCreating
    }

    if (!name) {
      return
    }

    const tagID = uuidv4()
    const tag: Tag = {
      _id: tagID,
      type: 'tag',
      name,
      parentID,
      children: [],
    }

    try {
      await localDB.put(tag)
      const updatedTag = await localDB.get(tag._id)
      this.context.commit('updateLocalData', updatedTag)

      if (parentID) {
        const parentTag = this.tags[parentID]
        parentTag.children.push(updatedTag._id)
        await localDB.put(parentTag)
        const updatedParentTag = await localDB.get(parentTag._id)
        this.context.commit('updateLocalData', updatedParentTag)
      }
    } catch (e) {
      this.context.commit('showError', e)
    }

    this.context.commit('setTagCreating', '')
  }

  @Action
  async updateTag() {
    if (!this.tagEditing._id) {
      return
    }

    const newTag = this.tagEditing
    if (newTag._id in this.tags) {
      const existingTag = this.tags[newTag._id]
      if (existingTag.name !== newTag.name) {
        try {
          await localDB.put(newTag)
          const updatedTag = await localDB.get(newTag._id)
          this.context.commit('updateLocalData', updatedTag)
        } catch (e) {
          this.context.commit('showError', e)
        }
      }
    }

    this.context.commit('setEditedTag', EMPTY_TAG)
    this.context.commit('setTagEditing', EMPTY_TAG)
    this.context.commit('setIsEditingTag', false)
  }

  @Action
  async removeTag(tag: FullTag) {
    const yes = confirm('Delete tag ' + tag.name + '?')
    if (!yes) {
      return
    }

    try {
      // Remove tag children from definitions
      {
        for (const childTag of tag.children) {
          const res = await localDB.find({
            selector: {
              type: 'definition',
              language: 'japanese',
              tags: {
                $elemMatch: {
                  '$eq': childTag._id
                }
              }
            }
          })

          for (const doc of res['docs']) {
            const definition = doc as JapaneseDefinition
            if (definition.tags) {
              definition.tags.splice(definition.tags.indexOf(childTag._id), 1)
              await localDB.put(definition)
              const updatedDefinition = await localDB.get(definition._id)
              this.context.commit('updateLocalData', updatedDefinition)
            }
          }
        }
      }

      // Remove tag from definitions
      {
        const res = await localDB.find({
          selector: {
            type: 'definition',
            language: 'japanese',
            tags: {
              $elemMatch: {
                '$eq': tag._id
              }
            }
          }
        })

        for (const doc of res['docs']) {
          const definition = doc as JapaneseDefinition
          if (definition.tags) {
            definition.tags.splice(definition.tags.indexOf(tag._id), 1)
            await localDB.put(definition)
            const updatedDefinition = await localDB.get(definition._id)
            this.context.commit('updateLocalData', updatedDefinition)
          }
        }
      }

      // Remove tag children
      {
        for (const childTag of tag.children) {
          const res = await localDB.find({
            selector: {
              _id: {$eq: childTag._id},
              type: {$eq: 'tag'},
            }
          })

          for (const doc of res['docs']) {
            await localDB.remove(doc)
            this.context.commit('removeLocalData', doc)
          }
        }
      }

      // Remove tag
      {
        const res = await localDB.find({
          selector: {
            _id: {$eq: tag._id},
            type: {$eq: 'tag'},
          }
        })

        for (const doc of res['docs']) {
          await localDB.remove(doc)
          this.context.commit('removeLocalData', doc)
        }
      }

      // Remove tag from parent
      if (tag.parentID) {
        const res = await localDB.find({
          selector: {
            _id: {$eq: tag.parentID},
            type: {$eq: 'tag'},
          }
        })

        for (const doc of res['docs']) {
          const parentTag = doc as Tag
          parentTag.children.splice(parentTag.children.indexOf(tag._id), 1)
          await localDB.put(parentTag)
          const updatedParentTag = await localDB.get(parentTag._id)
          this.context.commit('updateLocalData', updatedParentTag)
        }
      }
    } catch (e) {
      this.context.commit('showError', e)
    }
  }

  @Mutation
  setTags(tags: Record<string, Tag>) {
    this.tags = tags
  }

  @Action
  async loadTags() {
    const tags: Record<string, Tag> = {}

    try {
      const res = await localDB.find({
        selector: {
          type: {$eq: 'tag'},
        },
      })

      for (const doc of res['docs']) {
        tags[doc['_id']] = doc as Tag
      }

      this.context.commit('setTags', tags)
    } catch (e) {
      this.context.commit('showError', e)
    }
  }

  @Mutation
  setTagCreating(tag: string): void {
    this.tagCreating = tag
  }

  @Mutation
  editTag(tag: Tag) {
    this.editedTag = { ...tag }
    this.tagEditing = { ...tag }
    this.isEditingTag = true
  }

  @Mutation
  setEditedTag(tag: Tag): void {
    this.editedTag = tag
  }

  @Mutation
  setTagEditing(tag: Tag): void {
    this.tagEditing = tag
  }

  @Mutation
  setIsEditingTag(editing: boolean): void {
    this.isEditingTag = editing
  }

  @Mutation
  setSelectedTags(tags: string[]): void {
    this.selectedTags = tags
  }

  @Mutation
  setTagFilters(tagIDs: string[]) {
    this.tagFilters = tagIDs
  }

  @Mutation
  addTagFilter(id: string) {
    if (this.tagFilters.indexOf(id) < 0) {
      this.tagFilters.push(id)
    }
  }

  @Mutation
  removeTagFilter(id: string) {
    this.tagFilters.splice(this.tagFilters.indexOf(id), 1)
  }

  @Mutation
  toggleTagFilter(tagID: string) {
    if (this.tagFilters.indexOf(tagID) >= 0) {
      this.tagFilters.splice(this.tagFilters.indexOf(tagID), 1)
    } else {
      this.tagFilters.push(tagID)
    }
  }

  @Action
  async onTagSelected (tags: string[]) {
    if (!this.currentItem) {
      return
    }

    await this.context.dispatch('tagItems', { item: this.currentItem, tags })
  }

  @Action
  async tagItems(payload: {item: JapaneseDefinition, tags: string[]}) {
    if (!payload.item._rev) {
      return
    }

    try {
      payload.item.tags = payload.tags
      await localDB.put(payload.item)
      const updatedDoc = await localDB.get(payload.item._id)
      this.context.commit('updateLocalData', updatedDoc)
      this.context.commit('setCurrentItem', updatedDoc)
    } catch (e) {
      this.context.commit('showError', e)
    }
  }

  @Mutation
  setDefinitionDetailsDialog(value: boolean): void {
    this.definitionDetailsDialog = value
  }

  @Mutation
  setCurrentItem(item: JapaneseDefinition): void {
    this.currentItem = item
  }

  @Mutation
  setCurrentPage(page: number): void {
    this.currentPage = page
  }

  @Mutation
  setDefinitionDetailsDocument(document: JMDictDocument) {
    this.definitionDetailsDocument = document as JMDictDocument
  }

  @Mutation
  incrementLoadedShards(n: number) {
    this.loadedShards += n
  }

  @Action
  showDefinitionDetails(item: JapaneseDefinition) {
    const document = index.fetch(item._id.split('-')[1]).then((document: unknown) => {
      this.context.commit('setDefinitionDetailsDocument', document)
    })

    this.context.commit('setCurrentItem', item)
    this.context.commit('setSelectedTags', item.tags)
    this.context.commit('setDefinitionDetailsDialog', true)
  }

  @Action
  closeDefinitionDetails() {
    this.context.commit('setCurrentItem', null)
    this.context.commit('setSelectedTags', [])
    this.context.commit('setDefinitionDetailsDialog', false)
  }

  @Action
  async init(): Promise<void> {
    await index.load()
    await this.context.dispatch('loadLocal')
    this.context.dispatch('sync')
  }

  @Action
  async search(query: string): Promise<void> {
    if (!this.ready || query.length === 0) {
      return
    }

    query = preProcessQuery(query)

    try {
      this.context.commit('setSearching', true)
      const result = await index.search(query)
      this.context.commit('setSearching', false)
      this.context.commit('setHits', result.hits)
    } catch (e) {
      console.warn('Could not search "' + query + '" successfully: ' + e)
      this.context.commit('setSearching', false)
    }
  }

  @Action
  async loadDictionary(name: string): Promise<void> {
    if (!this.ready) {
      return
    }

    for (let i = 0; i < index.shardCount; i++) {
      await fetch([ASSETS_BASE_URL, name, 'index', i, 'dcs'].join('/'))
      await fetch([ASSETS_BASE_URL, name, 'index', i, 'tst'].join('/'))
      await fetch([ASSETS_BASE_URL, name, 'index', i, 'dst'].join('/'))
      this.context.commit('incrementLoadedShards', 1)
    }
  }

  @Action
  async toggleEntry(item: JapaneseDefinition) {
    try {
      const doc = await localDB.get(item._id)
      try {
        await localDB.remove(doc)
        this.context.commit('removeLocalData', doc)
      } catch (e) {
        this.context.commit('showError', e)
      }
    } catch (e: any) {
      if (e.status != 404) {
        this.context.commit('showError', e)
        return
      }

      const now = Date.now()
      const doc: JapaneseDefinition = {
        '_id': item._id,
        'type': 'definition',
        'language': 'japanese',
        'kanji': item.kanji || '',
        'kana': item.kana || '',
        'meaning': item.meaning || '',
        'labels': item.labels || [],
        'createdAt': now,
        'updatedAt': now,
      }

      /* TODO
      if (this.activeSeriesID && this.activeSeriesItemID) {
        doc.series = this.activeSeriesID
        doc.seriesItem = this.activeSeriesItemID
      }
      */

      try {
        await localDB.put(doc)
        const updatedDoc = await localDB.get(doc._id)
        this.context.commit('updateLocalData', updatedDoc)
      } catch (e) {
        this.context.commit('showError', e)
      }
    }
  }

  @Action
  async loadLocal(): Promise<void> {
    try {
      await this.context.dispatch('loadTags')

      const res = await localDB.find({
        selector: {
          type: {$eq: 'definition'},
          language: {$eq: 'japanese'},
        },
      })

      this.context.commit('reloadSavedDefinitions', res['docs'])
    } catch (e) {
      this.context.commit('showError', e)
    }
  }

  @Mutation
  setSyncing(payload: {active: boolean}) {
    this.syncing = payload.active
  }

  @Action
  sync(): void {
    // TODO
  }

  @Mutation
  updateLocalData(doc: any) {
    const typ = doc['type']
    if (typ === 'definition') {
      Vue.set(this.savedDefinitions, doc._id, doc)
    } else if (typ === 'tag') {
      Vue.set(this.tags, doc._id, doc)
    } else {
      console.error('Unknown type: ' + typ)
    }
  }

  @Mutation
  removeLocalData(doc: any) {
    let typ = ''

    if (doc._id in this.savedDefinitions) {
      typ = 'definition'
    } else if (doc._id in this.tags) {
      typ = 'tag'
    } else {
      console.error ('Unknown type for document: ' + doc._id)
      return
    }

    if (typ === 'definition') {
      Vue.delete(this.savedDefinitions, doc._id)
    } else if (typ === 'tag') {
      Vue.delete(this.tags, doc._id)
    }
  }

  @Action
  async loadDocuments(docs: any[]) {
    for (const doc of docs) {
      const newDoc = await localDB.get(doc._id)
      this.context.commit('updateLocalData', newDoc)
    }
  }

  @Action
  async submitTranslationDefinitionTestResult (payload: TranslationDefinitionTestResult) {
    const now = Date.now()
    const res = await localDB.find({
      selector: {
        type: {
          $eq: 'definition-test-result',
        },
        category: {
          $eq: 'translation',
        },
        definitionID: {
          $eq: payload.definitionID,
        },
      },
    })

    const correct = payload.correct

    if (res['docs'].length === 0) {
      payload._id = uuidv4()
      payload.createdAt = now
    } else if (res['docs'].length === 1) {
      payload = res['docs'][0] as TranslationDefinitionTestResult
      payload.correct = correct
    } else {
      console.error('Found more than one translation definition test results! This shouldn\'t happen')
      return
    }
    payload.updatedAt = now

    try {
      await localDB.put(payload)
    } catch (e) {
      this.context.commit('showError', e)
    }
  }

  @Mutation
  setShowFilterDialog(payload: {show: boolean}) {
    this.bShowFilterDialog = payload.show
  }

  @Mutation
  toggleFilterDialog() {
    this.bShowFilterDialog = !this.bShowFilterDialog
  }

  @Mutation
  clearFilters() {
    this.tagFilters = []
  }

  get loadedShardsPercentage(): number {
    if (!this.ready) {
      return 0
    }
    return Math.round(this.loadedShards * 100 / index.shardCount)
  }

  get savedDefinitionsList(): JapaneseDefinition[] {
    const list: JapaneseDefinition[] = []

    for (const _id in this.savedDefinitions) {
      const entry = this.savedDefinitions[_id]
      let ok = true

      // Apply tags filter
      for (const tagID of this.tagFilters) {
        if (!entry.tags || (entry.tags && entry.tags.indexOf(tagID) < 0)) {
          ok = false
          break
        }
      }

      if (!ok) {
        continue
      }

      list.push({
        ...entry,
      })
    }

    list.sort((a, b) => {
      if (b.createdAt && a.createdAt) {
        return b.createdAt - a.createdAt
      }
      return 0
    })

    return list
  }

  get savedDefinitionsOfCurrentPage(): JapaneseDefinition[] {
    const list = this.savedDefinitionsList
    const start = (this.currentPage - 1) * 10
    let end = (this.currentPage) * 10
    if (end >= this.pageCount * 10) {
      end = (this.pageCount * 10) - 1
    }
    return list.slice(start, end)
  }

  get pageCount() {
    return Math.round(this.savedDefinitionsList.length / 10) + 1
  }

  get likesAllTags(): boolean {
    return this.selectedTags.length === Object.keys(this.tags).length
  }

  get likesSomeTags(): boolean {
    return this.selectedTags.length > 0
  }

  get tagIcon(): string {
    if (this.likesAllTags) return 'mdi-close-box'
    if (this.likesSomeTags) return 'mdi-minus-box'
    return 'mdi-checkbox-blank-outline'
  }

  get searchResult(): JapaneseDefinition[] {
    const items: JapaneseDefinition[] = []

    for (const hit of this.hits) {
      const source = hit.source as any
      const item = {
        '_id': ['jmdict', hit.id].join('-'),
        'type': 'definition',
        'language': 'japanese',
        'kanji': source.Kanji.Expression || '',
        'kana': source.Readings.Reading || '',
        'meaning': source.Sense.Glossary.Content || '',
        'tags': [],
        'labels': [],
      } as JapaneseDefinition
      if (item._id in this.savedDefinitions) {
        items.push(this.savedDefinitions[item._id])
      } else {
        items.push(item)
      }
    }

    return items
  }

  get localDB(): PouchDB.Database {
    return localDB
  }
}
export default Japanese