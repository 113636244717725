
import Vue from 'vue'
import Component from 'vue-class-component'
import { namespace } from 'vuex-class'
import { getModule } from 'vuex-module-decorators';
const japanese = namespace('japanese')

import TagManagement from '@/components/japanese/TagManagement.vue'
import { Tag } from '@/lib'
import JapaneseModule from '@/store/modules/japanese'

const Props = Vue.extend({
  props: {
    bus: Vue,
  }
})

@Component({
  name: 'Manage',
  components: {
    TagManagement,
  },
})
export default class Manage extends Props {
  // Tags
  @japanese.Action
  createTag!: () => Promise<void>

  @japanese.Action
  updateTag!: () => Promise<void>

  /* eslint-disable no-unused-vars */
  @japanese.Mutation
  editTag!: (tag: Tag) => Promise<void>

  @japanese.Action
  removeTag!: (tag: Tag) => Promise<void>

  get tags(): Record<string, Tag> {
    const module = getModule(JapaneseModule, this.$store);
    return module.tags
  }

  get isEditingTag(): boolean {
    const module = getModule(JapaneseModule, this.$store);
    return module.isEditingTag
  }

  get tagCreating(): string {
    const module = getModule(JapaneseModule, this.$store);
    return module.tagCreating
  }

  set tagCreating(value: string) {
    const module = getModule(JapaneseModule, this.$store);
    module.setTagCreating(value)
  }

  get tagEditing(): Tag {
    const module = getModule(JapaneseModule, this.$store);
    return module.tagEditing
  }
}
