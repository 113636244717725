import { VuexModule, Module, Action, Mutation } from 'vuex-module-decorators'
import Keycloak from 'keycloak-js'

import { AUTH_URL } from '@/common'

const keycloakOptions: Keycloak.KeycloakConfig = {
  url: AUTH_URL,
  realm: 'veandco',
  clientId: 'language-journal',
}

@Module({ name: 'auth', namespaced: true })
class Auth extends VuexModule {
  keycloak = Keycloak(keycloakOptions)

  @Mutation
  onKeycloakInit (auth: any) {
    if (auth) {
      console.info("Authenticated")
    }

    if (this.keycloak.token) {
      localStorage.setItem("vue-token", this.keycloak.token)
    }
    if (this.keycloak.refreshToken) {
      localStorage.setItem("vue-refresh-token", this.keycloak.refreshToken)
    }

    // Token Refresh
    setInterval(() => {
      this.keycloak.updateToken(70).then((refreshed) => {
        if (refreshed) {
          console.info('Token refreshed', refreshed)
        } else {
          if (this.keycloak.tokenParsed && this.keycloak.tokenParsed.exp && this.keycloak.timeSkew) {
            console.warn('Token not refreshed, valid for '
              + Math.round(this.keycloak.tokenParsed.exp + this.keycloak.timeSkew - new Date().getTime() / 1000) + ' seconds')
          }
        }
      }).catch(() => {
        console.error('Could not refresh token')
      })
    }, 6000)
  }

  @Action
  async init(callback: () => void): Promise<void> {
    try {
      const auth = await this.keycloak.init({
        onLoad: 'login-required',
        checkLoginIframe: false,
      })

      this.context.commit('onKeycloakInit', auth)
      callback()
    } catch(e) {
      console.error("Authenticated Failed")
    }
  }
}
export default Auth