
import Vue from 'vue'
import Component from 'vue-class-component'
import { getModule } from 'vuex-module-decorators';

import CommonModule from '@/store/modules/common'

const Props = Vue.extend({
  props: {
  }
})

@Component({
  name: 'SettingsDialog',
})
export default class DefinitionActions extends Props {
  close() {
    const module = getModule(CommonModule, this.$store);
    module.setSettingsDialogVisibility(false)
  }

  /* eslint-disable no-unused-vars */
  /* eslint-enable no-unused-vars */

  get settingsDialogVisible(): boolean {
    const module = getModule(CommonModule, this.$store);
    return module.settingsDialogVisible
  }

  set settingsDialogVisible(visible: boolean) {
    const module = getModule(CommonModule, this.$store);
    module.setSettingsDialogVisibility(visible)
  }

  get themeOptions(): string[] {
    const module = getModule(CommonModule, this.$store);
    return module.themeOptions
  }

  get theme(): string {
    const module = getModule(CommonModule, this.$store);
    return module.settings.theme
  }

  set theme(theme: string) {
    const module = getModule(CommonModule, this.$store);
    module.setTheme(theme)
  }

  get remoteDatabaseURL(): string {
    const module = getModule(CommonModule, this.$store);
    return module.localSettings.remoteDatabaseURL
  }

  set remoteDatabaseURL(remoteDatabaseURL: string) {
    const module = getModule(CommonModule, this.$store);
    module.setRemoteDatabaseURL(remoteDatabaseURL)
  }

  get requiresAuthentication(): boolean {
    const module = getModule(CommonModule, this.$store);
    return module.localSettings.requiresAuthentication
  }

  set requiresAuthentication(requiresAuthentication: boolean) {
    const module = getModule(CommonModule, this.$store);
    module.setRequiresAuthentication(requiresAuthentication)
  }
}
