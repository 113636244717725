
import Vue from 'vue'
import Component from 'vue-class-component'
import { namespace } from 'vuex-class'
import { getModule } from 'vuex-module-decorators';

import { Tag, arrayFromMap, FullTag } from '@/lib'
import JapaneseModule from '@/store/modules/japanese'
const japanese = namespace('japanese')

const Props = Vue.extend({
  props: {
    parentTagID: String,
  },
})

@Component({
  name: 'TagSelection',
})
export default class TagSelection extends Props {
  get tags(): Record<string, Tag> {
    const module = getModule(JapaneseModule, this.$store);
    return module.tags
  }

  get tagFilters(): string[] {
    const module = getModule(JapaneseModule, this.$store);
    return module.tagFilters
  }

  get tagCreating(): string {
    const module = getModule(JapaneseModule, this.$store);
    return module.tagCreating
  }

  set tagCreating(value: string) {
    const module = getModule(JapaneseModule, this.$store);
    module.setTagCreating(value)
  }

  arrayFromMap (m: Record<string, unknown>): unknown[] {
    return arrayFromMap(m)
  }

  parentTagsOnly (m: Record<string, Tag>): Record<string, FullTag> {
    const n: Record<string, FullTag> = {}

    for (const tagID in m) {
      const tag = m[tagID]
      const fullTag: FullTag = {
        _id: tag._id,
        type: tag.type,
        name: tag.name,
        children: tag.children.map((childTag: string) => this.tags[childTag]),
        parentID: tag.parentID,
      }
      if (tag.parentID === this.parentTagID) {
        n[tagID] = fullTag
      }
    }

    return n
  }

  /* eslint-disable no-unused-vars */
  @japanese.Mutation
  setTagFilters!: (tags: string[]) => Promise<void>
  /* eslint-enable no-unused-vars */

  @japanese.Action
  createTag!: () => Promise<void>

  onTagSelected (tags: string[]) {
    this.setTagFilters(tags)
  }
}
