
import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import { getModule } from 'vuex-module-decorators';
const japanese = namespace('japanese')

import TagSelection from '@/components/japanese/TagSelection.vue'
import { arrayFromMap } from '@/lib'
import { JapaneseDefinition, JMDictDocument } from '@/lib/japanese'
import JapaneseModule from '@/store/modules/japanese'

const Props = Vue.extend({
  props: {
  }
})

@Component({
  name: 'DefinitionDetails',
  components: {
    TagSelection,
  },
})
export default class DefinitionDetails extends Props {
  selectedSeriesIndex = -1

  arrayFromMap (m: Record<string, unknown>): unknown[] {
    return arrayFromMap(m)
  }

  get currentItem(): JapaneseDefinition | null {
    const module = getModule(JapaneseModule, this.$store);
    return module.currentItem
  }

  get definitionDetailsDocument(): JMDictDocument | null {
    const module = getModule(JapaneseModule, this.$store);
    return module.definitionDetailsDocument
  }

  /* eslint-disable no-unused-vars */
  @japanese.Action
  closeDefinitionDetails!: () => Promise<void>
  /* eslint-enable no-unused-vars */
}
