
import Vue from 'vue'
import Component from 'vue-class-component'
import { getModule } from 'vuex-module-decorators';
import { namespace } from 'vuex-class'
const japanese = namespace('japanese')

import Search from '@/components/japanese/Search.vue'
import Library from '@/components/japanese/Library.vue'
import Manage from '@/components/japanese/Manage.vue'
import DefinitionDetails from '@/components/japanese/DefinitionDetails.vue'
import FilterDialog from '@/components/japanese/FilterDialog.vue'

import JapaneseModule from '@/store/modules/japanese'

const Props = Vue.extend({
  props: {
  }
})

@Component({
  name: 'Japanese',
  components: {
    Search,
    Library,
    Manage,
    DefinitionDetails,
    FilterDialog,
  },
})
export default class Japanese extends Props {
  tab = null

  /* eslint-disable no-unused-vars */
  @japanese.Mutation
  setReady!: (ready: boolean) =>  void

  @japanese.Action
  sync!: () => void

  @japanese.Action
  loadDictionary!: (name: string) => void
  /* eslint-enable no-unused-vars */

  @japanese.Action
  closeDefinitionDetails!: () => void

  async created () {
    this.setReady(true)
  }

  get infoSnackbar(): boolean {
    const module = getModule(JapaneseModule, this.$store);
    return module.infoSnackbar
  }

  set infoSnackbar(value: boolean) {
    const module = getModule(JapaneseModule, this.$store);
    module.setInfoSnackbar(value)
  }

  get infoSnackbarText(): string {
    const module = getModule(JapaneseModule, this.$store);
    return module.infoSnackbarText
  }

  get errorSnackbar(): boolean {
    const module = getModule(JapaneseModule, this.$store);
    return module.errorSnackbar
  }

  set errorSnackbar(value: boolean) {
    const module = getModule(JapaneseModule, this.$store);
    module.setErrorSnackbar(value)
  }

  get errorSnackbarText(): string {
    const module = getModule(JapaneseModule, this.$store);
    return module.errorSnackbarText
  }

  get definitionDetailsDialog(): boolean {
    const module = getModule(JapaneseModule, this.$store);
    return module.definitionDetailsDialog
  }

  set definitionDetailsDialog(value: boolean) {
    const module = getModule(JapaneseModule, this.$store);
    module.setDefinitionDetailsDialog(value)
  }
}
