import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators'
import { localDB } from '@/lib/common'

const DEFAULT_THEME = 'system'

@Module({ name: 'common', namespaced: true })
class Common extends VuexModule {
  settingsDialogVisible: boolean = false

  themeOptions = ['system', 'light', 'dark']

  settings = {
    theme: DEFAULT_THEME,
  }

  localSettings = {
    remoteDatabaseURL: '',
    requiresAuthentication: false,
  }

  @Mutation
  setSettingsDialogVisibility (visible: boolean) {
    this.settingsDialogVisible = visible
  }

  @Mutation
  async refreshSettings() {
    const res: any = await localDB.get('settings')
    this.settings = res.settings
  }

  @Mutation
  setSettings(settings: any) {
    this.settings = settings
  }

  @Mutation
  setLocalSettings(localSettings: any) {
    this.localSettings = localSettings
  }

  @Action
  async loadSettings (): Promise<void> {
    const localSettingsString = window.localStorage.getItem('settings')
    if (localSettingsString) {
      this.context.commit('setLocalSettings', JSON.parse(localSettingsString))
    }
    this.context.commit('refreshSettings')
  }

  @Action
  setTheme (theme: string): void {
    this.settings.theme = theme
    this.context.dispatch('updateSettings')
  }

  @Action
  setRemoteDatabaseURL (remoteDatabaseURL: string): void {
    this.localSettings.remoteDatabaseURL = remoteDatabaseURL
    this.context.dispatch('updateLocalSettings')
  }

  @Action
  setRequiresAuthentication (requiresAuthentication: boolean): void {
    this.localSettings.requiresAuthentication = requiresAuthentication
    this.context.dispatch('updateLocalSettings')
  }

  @Action
  async updateSettings(): Promise<void> {
    try {
      const oldSettings = await localDB.get('settings')
      await localDB.put({
        _id: 'settings',
        _rev: oldSettings._rev,
        type: 'settings',
        settings: this.settings,
      })
    } catch (e: any) {
      await localDB.put({
        _id: 'settings',
        type: 'settings',
        settings: this.settings,
      })
    }
  }

  @Action
  async updateLocalSettings(): Promise<void> {
    window.localStorage.setItem('settings', JSON.stringify(this.localSettings))
  }
}
export default Common