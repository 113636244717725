import { render, staticRenderFns } from "./TagSelection.vue?vue&type=template&id=d0144d70&scoped=true&"
import script from "./TagSelection.vue?vue&type=script&lang=ts&"
export * from "./TagSelection.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d0144d70",
  null
  
)

export default component.exports