
import Vue from 'vue'
import Component from 'vue-class-component'
import { getModule } from 'vuex-module-decorators';
import { Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class'
import Keycloak from 'keycloak-js'
import { GIT_COMMIT_ID } from '@/common'

const common = namespace('common')
import CommonModule from '@/store/modules/common'
import SettingsDialog from '@/components/SettingsDialog.vue'

const japanese = namespace('japanese')
import Japanese from '@/components/Japanese.vue'
import JapaneseModule from '@/store/modules/japanese'
import { startPouchDB, stopPouchDB } from './lib/common';
import AuthModule from './store/modules/auth';

const auth = namespace('auth')

@Component({
  name: 'App',
  components: {
    SettingsDialog,
    Japanese,
  },
})
export default class App extends Vue {
  drawer = false

  @auth.State
  keycloak!: Keycloak.KeycloakInstance

  /* eslint-disable no-unused-vars */
  @common.Action
  loadSettings!: () => void

  @common.Mutation
  setSettingsDialogVisibility!: (visible: boolean) =>  void

  @common.Mutation
  setSettings!: (settings: any) => void

  @japanese.Mutation
  removeLocalData!: (changes: any) => void

  @japanese.Mutation
  updateLocalData!: (changes: any) => void

  @japanese.Mutation
  setSyncing!: (payload: { active: boolean }) => void

  @japanese.Mutation
  showError!: (msg: string) => void
  /* eslint-enable no-unused-vars */

  get gitCommitID(): string | undefined {
    return GIT_COMMIT_ID
  }

  async created() {
    await this.loadSettings()
    if (this.theme === 'system') {
      this.onThemeChange(this.theme)
    }

    await this.initiateLoginIfRequired()

    document.addEventListener('visibilitychange', this.onVisibilityChange)

    if (navigator.serviceWorker) {
      navigator.serviceWorker.addEventListener('message', this.onServiceWorkerMessage)
    }
  }

  onVisibilityChange() {
    if (document.hidden) {
      this.onPause()
    } else {
      this.onResume()
    }
  }

  onPause() {
    stopPouchDB()
  }

  onResume() {
    if (this.keycloak && this.keycloak.authenticated) {
      this.keycloak.updateToken(70).then(() => {
        startPouchDB(this.remoteDatabaseURL, this.keycloak, this.onCouchDBPull, this.onCouchDBPaused, this.onCouchDBActive, this.onCouchDBError)
      })
    }
  }

  logIn() {
    this.initiateLoginIfRequired()
  }

  logOut() {
    if (this.keycloak.authenticated) {
      this.keycloak.logout()
    }
  }

  openSettingsDialog() {
    this.setSettingsDialogVisibility(true)
  }

  toggleLightDarkTheme() {
    this.$vuetify.theme.dark = !this.$vuetify.theme.dark
  }

  requestNotificationPermission() {
    Notification.requestPermission().then((permission) => {
      if (permission === 'denied' || permission === 'default') {
        return
      }

      window.setTimeout(() => {
        this.spawnNotification(
          'Title',
          'Lorem ipsum dolor sit amet consectetur.',
          'https://image.flaticon.com/icons/png/512/4481/4481058.png',
          [
            {
              'action': 'test',
              'title': 'Take the test!'
            }
          ],
        )
      }, 3000)
    })
  }

  spawnNotification(title: string, body: string, icon: string, actions: any[]) {
    if (!navigator.serviceWorker) {
      return
    }

    navigator.serviceWorker.ready.then(registration => {
      if (registration.active) {
        registration.active.postMessage({ type: 'SPAWN_NOTIFICATION', title, body, icon, actions })
      }
    })
  }

  onServiceWorkerMessage(event: any) {
    if (event.data.action === 'test') {
      alert('Test Notification Was Successful!')
    }
  }

  get theme(): string {
    const module = getModule(CommonModule, this.$store);
    return module.settings.theme
  }

  get remoteDatabaseURL(): string {
    const module = getModule(CommonModule, this.$store);
    return module.localSettings.remoteDatabaseURL
  }

  get requiresAuthentication(): boolean {
    const module = getModule(CommonModule, this.$store);
    return module.localSettings.requiresAuthentication
  }

  @Watch('theme')
  onThemeChange(theme: string) {
    if (theme === 'system') {
      this.watchSystemTheme()
    } else if (theme === 'dark') {
      this.$vuetify.theme.dark = true
    } else if (theme === 'light') {
      this.$vuetify.theme.dark = false
    }
  }

  watchSystemTheme() {
    if (!window.matchMedia) {
      return
    }

    const media = window.matchMedia('(prefers-color-scheme: dark)')
    media.removeEventListener('change', this.onSystemThemeChange)

    if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
      this.$vuetify.theme.dark = true
    } else {
      this.$vuetify.theme.dark = false
    }

    media.addEventListener('change', this.onSystemThemeChange)
  }

  onSystemThemeChange(event: any) {
    const theme = event.matches ? "dark" : "light"
    if (theme === 'dark') {
      this.$vuetify.theme.dark = true
    } else {
      this.$vuetify.theme.dark = false
    }
  }

  async initiateLoginIfRequired() {
    if (navigator.onLine && this.requiresAuthentication) {
      const authModule = getModule(AuthModule, this.$store)
      await authModule.init(() => {
        startPouchDB(this.remoteDatabaseURL, this.keycloak, this.onCouchDBPull, this.onCouchDBPaused, this.onCouchDBActive, this.onCouchDBError)
      })
    } else {
      //TODO: initRemoteDBWithCouchDB(remoteDatabaseURL, '', '')
    }
  }

  async onCouchDBPull(info: any) {
    for (const doc of info.change['docs']) {
      const typ = doc['type']
      if (typ === 'definition' || typ === 'tag') {
        if ('_deleted' in doc && doc['_deleted']) {
          this.removeLocalData(doc)
        } else {
          this.updateLocalData(doc)
        }
      } else if (typ === 'settings') {
        this.setSettings(doc.settings)
      }
    }
  }

  async onCouchDBPaused() {
    await this.setSyncing({ active: false })
  }

  async onCouchDBActive() {
    await this.setSyncing({ active: true })
  }

  async onCouchDBError(e: any) {
    this.showError(JSON.stringify(e))
  }

  get syncing(): boolean {
    const module = getModule(JapaneseModule, this.$store);
    return module.syncing
  }
}
