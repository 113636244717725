
import Vue from 'vue'
import Component from 'vue-class-component'
import { namespace } from 'vuex-class'
import { getModule } from 'vuex-module-decorators';

import DefinitionListItem from '@/components/DefinitionListItem.vue'
import { JapaneseDefinition } from '@/lib/japanese'
import JapaneseModule from '@/store/modules/japanese'
const japanese = namespace('japanese')

const Props = Vue.extend({
  props: {
    bus: Vue,
  }
})

@Component({
  name: 'Library',
  components: {
    DefinitionListItem,
  },
})
export default class Library extends Props {
  @japanese.State
  savedDefinitions!: {[key: string]: JapaneseDefinition}

  @japanese.Getter
  savedDefinitionsOfCurrentPage!: JapaneseDefinition[]

  @japanese.Getter
  pageCount!: number

  get filterExists(): boolean {
    const module = getModule(JapaneseModule, this.$store);
    return module.tagFilters.length > 0
  }

  /* eslint-disable no-unused-vars */
  @japanese.Mutation
  toggleFilterDialog!: () => void

  @japanese.Mutation
  clearFilters!: () => void

  @japanese.Mutation
  removeTagFilter!: (id: string) => void

  @japanese.Action
  showDefinitionDetails!: (item: JapaneseDefinition) => void

  @japanese.Action
  toggleEntry!: (item: JapaneseDefinition) => void
  /* eslint-enable no-unused-vars */

  get currentPage(): number {
    const module = getModule(JapaneseModule, this.$store)
    return module.currentPage
  }

  set currentPage(value: number) {
    const module = getModule(JapaneseModule, this.$store)
    module.setCurrentPage(value)
  }

  get tagFilters(): string[] {
    const module = getModule(JapaneseModule, this.$store)
    return module.tagFilters
  }

  nameOfTag(id: string): string {
    const module = getModule(JapaneseModule, this.$store)
    return module.tags[id].name
  }
}
