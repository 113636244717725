
import Vue from 'vue'
import Component from 'vue-class-component'
import { namespace } from 'vuex-class'

import DefinitionListItem from '@/components/DefinitionListItem.vue'
import { JapaneseDefinition } from '@/lib/japanese'
const japanese = namespace('japanese')

const Props = Vue.extend({
  props: {
    bus: Vue,
  }
})

@Component({
  name: 'Search',
  components: {
    DefinitionListItem,
  },
})
export default class Search extends Props {
  @japanese.State
  ready!: boolean

  @japanese.State
  searching!: boolean

  @japanese.Getter
  searchResult!: JapaneseDefinition[]

  @japanese.Action
  init!: () => Promise<void>

  query: string = ''

  /* eslint-disable no-unused-vars */
  @japanese.Action
  search!: (query: string) => any
  /* eslint-enable no-unused-vars */

  @japanese.Getter
  loadedShardsPercentage!: number

  async created() {
    await this.init()
  }

  async onSearch() {
    await this.search(this.query)
  }
}
