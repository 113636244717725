import PouchDB from 'pouchdb'
import PouchDBFind from 'pouchdb-find'
import PouchDBAuthentication from 'pouchdb-authentication'

import { IS_PRODUCTION, USE_KEYCLOAK } from '@/common'
import { stringToHex } from '@/lib'

PouchDB.plugin(PouchDBFind)
PouchDB.plugin(PouchDBAuthentication)
const POUCHDB_NAME = IS_PRODUCTION ? 'japanese' : USE_KEYCLOAK ? 'japanese-development-keycloak' : 'japanese-development'
export const localDB: PouchDB.Database = new PouchDB(POUCHDB_NAME)
export let remoteDB: PouchDB.Database | null = null
let syncHandler: any | null = null

export function initRemoteDBWithCouchDB(dbURL: string, dbUsername: string, dbPassword: string, onPull: (info: any) => void, onPaused: () => void, onActive: () => void, onError: (e: any) => void): void {
  const user = {
    name: dbUsername,
    password: dbPassword,
  }

  remoteDB = new PouchDB(dbURL, {
    fetch: (url, opts): Promise<Response> => {
      if (opts && opts.headers) {
        (opts.headers as Headers).append('Authorization', 'Basic ' + window.btoa(user.name + ':' + user.password))
      }
      return PouchDB.fetch(url, opts)
    },
  })

  startSyncWithRemote(localDB, remoteDB, onPull, onPaused, onActive, onError)
}

export function startPouchDB(dbURL: string, keycloak: any, onPull: (info: any) => void, onPaused: () => void, onActive: () => void, onError: (e: any) => void): void {
  const hexSubject = stringToHex(keycloak.subject)
  remoteDB = new PouchDB(dbURL + hexSubject, {
    fetch: (url, opts): Promise<Response> => {
      if (opts && opts.headers) {
        (opts.headers as Headers).append('Authorization', 'Bearer ' + keycloak.token)
      }
      return PouchDB.fetch(url, opts)
    },
  })

  startSyncWithRemote(localDB, remoteDB, onPull, onPaused, onActive, onError)
}

function startSyncWithRemote(localDB: PouchDB.Database, remoteDB: PouchDB.Database, onPull: (info: any) => void, onPaused: () => void, onActive: () => void, onError: (e: any) => void) {
  syncHandler = localDB.sync(remoteDB, {
    live: true,
    retry: true,
  }).on('complete', (info: any) => {
    if (info.direction === 'pull') {
      onPull(info)
    }
  }).on('change', (info: any) => {
    if (info.direction === 'pull') {
      onPull(info)
    }
  })
  .on('paused', onPaused)
  .on('active', onActive)
  .on('error', onError)
}

function stopSyncWithRemote() {
  if (syncHandler) {
    syncHandler.cancel()
    syncHandler = null
  }
}

export function stopPouchDB() {
  stopSyncWithRemote()

  if (remoteDB) {
    remoteDB.close()
  }
}