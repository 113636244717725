
import Vue from 'vue'
import Component from 'vue-class-component'
import { namespace } from 'vuex-class'
import { getModule } from 'vuex-module-decorators';

import FilterTagSelection from '@/components/japanese/FilterTagSelection.vue'
import { Tag } from '@/lib';
import JapaneseModule from '@/store/modules/japanese'
const japanese = namespace('japanese')

const Props = Vue.extend({
  props: {
  }
})

@Component({
  name: 'FilterDialog',
  components: {
    FilterTagSelection,
  },
})
export default class DefinitionActions extends Props {
  selectedSeriesIndex = -1

  close() {
    const module = getModule(JapaneseModule, this.$store);
    module.setShowFilterDialog({show: false})
  }

  get bShowFilterDialog(): boolean {
    const module = getModule(JapaneseModule, this.$store);
    return module.bShowFilterDialog
  }

  /* eslint-disable no-unused-vars */
  @japanese.Mutation
  toggleTagFilter!: (labelID: string) => void
  /* eslint-enable no-unused-vars */

  onTagClick(tagID: string) {
    this.toggleTagFilter(tagID)
  }

  get tags(): Record<string, Tag> {
    const module = getModule(JapaneseModule, this.$store)
    return module.tags
  }

  get tagFilters(): string[] {
    const module = getModule(JapaneseModule, this.$store)
    return module.tagFilters
  }
}
