
import Vue, { PropType } from 'vue'
import Component from 'vue-class-component'
import { namespace } from 'vuex-class'
import { getModule } from 'vuex-module-decorators';

import { JapaneseDefinition } from '@/lib/japanese'
import JapaneseModule from '@/store/modules/japanese'
const japanese = namespace('japanese')

const Props = Vue.extend({
  props: {
    item: Object as PropType<JapaneseDefinition>,
  }
})

@Component({
  name: 'DefinitionActions',
})
export default class DefinitionActions extends Props {
  /* eslint-disable no-unused-vars */
  @japanese.Action
  showDefinitionDetails!: (item: JapaneseDefinition) => void

  @japanese.Action
  toggleEntry!: (item: JapaneseDefinition) => void
  /* eslint-enable no-unused-vars */

  get savedDefinitions(): {[key: string]: JapaneseDefinition} {
    const module = getModule(JapaneseModule, this.$store);
    return module.savedDefinitions
  }
}
